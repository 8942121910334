import { Form, useFormikContext } from 'formik';
import React, { FC, useEffect } from 'react';
import { NewPlan } from '../../../../model/plan';
import {
  Button,
  Column,
  ColumnedRow,
  Content,
  CountrySelect,
  Input,
  Loader,
  OrderSummary,
  RadioInput,
  Row,
  StripeStamp,
  TermsAndServicesText,
} from './styles';
import { SubscriptionForm } from './types';
import { MAX_CHARACTERS_CUT, MAX_CHARACTERS_FULL } from './constants';

const Link: FC<{ href: string }> = ({ href, children }) => {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

export type SubmitFormProps = {
  plan: NewPlan;
  initialValues: SubscriptionForm;
  onChange: (country: string) => void;
  europeanCountry: boolean;
  handleTypeOfBusinessChange: (isB2B: boolean) => void;
  amount: any;
  quota: number;
  currency: 'EUR' | 'USD' | 'INR';
  billingInterval: string | undefined;
  canMonthly: boolean;
  taxRate: any;
  taxRateIncluded: boolean;
  onSeatsChange: (seatsGiven: number) => void;
  onBillingIntervalChange: (billingIntervalGiven: string) => void;
  disableCheckoutButton: boolean;
  typeOfBusiness: 'B2B' | 'B2C';
};
export const SubmitForm = ({
  plan,
  initialValues,
  onChange,
  europeanCountry,
  handleTypeOfBusinessChange,
  amount,
  quota,
  currency,
  billingInterval,
  canMonthly,
  taxRate,
  taxRateIncluded,
  onSeatsChange,
  onBillingIntervalChange,
  disableCheckoutButton,
  typeOfBusiness,
}: SubmitFormProps) => {
  const formik = useFormikContext<SubscriptionForm>();

  useEffect(() => {
    if (formik.touched.isBusinessPurchase) {
      formik.setFieldValue('isBusinessPurchase', typeOfBusiness === 'B2B');
      formik.setFieldValue('vat', '');
      formik.setFieldValue('name', '');
      formik.setFieldValue('companyName', '');
      formik.setTouched({
        name: false,
        companyName: false,
        vat: false,
      });
    }
  }, [typeOfBusiness]);

  return (
    <Form noValidate data-test-id="pricing-form">
      <Content>
        <Column>
          {plan === 'TEAM' ? (
            <Row>
              <Input
                label="Domain"
                name="domain"
                placeholder="example.com"
                maxLength={MAX_CHARACTERS_FULL}
                validateOnMount
              />
            </Row>
          ) : null}
          <Row>
            <CountrySelect
              label="Country"
              name="country"
              placeholder="Select country"
              defaultValue={initialValues.country}
              onChange={onChange}
            />
          </Row>
          {europeanCountry ? (
            <Row>
              <RadioInput
                label="Is this a business purchase?"
                name="isBusinessPurchase"
                onChange={(val) => {
                  formik.setTouched({
                    isBusinessPurchase: true,
                  });
                  handleTypeOfBusinessChange(val);
                }}
              />
              {formik.values.isBusinessPurchase ? (
                <Input label="VAT ID" name="vat" />
              ) : null}
            </Row>
          ) : null}
          {europeanCountry && formik.values.isBusinessPurchase ? (
            <Row>
              <Input
                label="Company Name"
                name="companyName"
                maxLength={MAX_CHARACTERS_FULL}
              />
            </Row>
          ) : null}
          <Row>
            <Input
              label="Name"
              name="name"
              maxLength={
                formik.values.isBusinessPurchase
                  ? MAX_CHARACTERS_CUT
                  : MAX_CHARACTERS_FULL
              }
            />
          </Row>
          <Row>
            <Input
              label="Street address"
              name="street"
              maxLength={MAX_CHARACTERS_FULL}
            />
          </Row>
          <ColumnedRow>
            <Input label="City" name="city" maxLength={MAX_CHARACTERS_FULL} />
            <Input
              isOptional
              label="State / Province"
              name="state"
              maxLength={MAX_CHARACTERS_FULL}
            />
          </ColumnedRow>
          <Row>
            <Input
              isOptional
              label="Postal code"
              name="code"
              maxLength={MAX_CHARACTERS_CUT}
            />
          </Row>
        </Column>
        <Column>
          <OrderSummary
            amount={amount}
            nameSeats="seats"
            nameBillingInterval="billingInterval"
            plan={plan}
            quota={quota}
            currency={currency}
            billingInterval={billingInterval}
            canMonthly={canMonthly}
            taxRate={taxRate}
            taxRateIncluded={taxRateIncluded}
            onSeatsChange={onSeatsChange}
            onBillingIntervalChange={onBillingIntervalChange}
          />
          <Button
            type="submit"
            disabled={formik.isSubmitting || disableCheckoutButton}
          >
            {formik.isSubmitting ? <Loader /> : 'Go to checkout'}
          </Button>
          <StripeStamp />
          <TermsAndServicesText>
            All payments are securely processed by Stripe. By clicking on
            &quot;Go to checkout&quot; and then confirming your subscription,
            you agree with Stripe&apos;s{' '}
            <Link href="https://stripe.com/legal/end-users">terms</Link> and{' '}
            <Link href="https://stripe.com/privacy">privacy policy</Link>.
          </TermsAndServicesText>
        </Column>
      </Content>
    </Form>
  );
};
