import { NewPlan } from 'model/plan';
import * as Yup from 'yup';

export const getInitialValues = (
  domain: string | undefined,
  defaultPlan: NewPlan,
  defaultSeats?: number,
  defaultBillingInterval?: string,
) => ({
  city: '',
  code: '',
  country: '',
  domain: domain || '',
  isBusinessPurchase: false,
  name: '',
  companyName: '',
  plan: defaultPlan,
  seats: defaultPlan === 'TEAM' ? defaultSeats || 5 : 1,
  billingInterval: defaultBillingInterval || 'YEARLY',
  state: '',
  street: '',
  vat: '',
});

export const DOMAIN_REGEX = /^([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]*\.[a-zA-Z]{2,11}$/;
const COUNTRY_CODE_REGEX = /^[A-Z]{2}$/;

export const MAX_CHARACTERS_CUT = 30;
export const MAX_CHARACTERS_FULL = 256;


export const validationSchema = (plan: NewPlan, handleDomainChange: (v: string | undefined) => Promise<boolean>) => {
  const baseSchema = {
    plan: Yup.string().required(),
    seats: Yup.number().oneOf([1, 5, 10, 25, 50, 100]),
    billingInterval: Yup.string().oneOf(['MONTHLY', 'YEARLY']),
    country: Yup.string()
      .matches(COUNTRY_CODE_REGEX, 'Country is a required field')
      .length(2)
      .required('Country is a required field'),
    isBusinessPurchase: Yup.boolean().required(),
    vat: Yup.string().when('isBusinessPurchase', {
      is: true,
      then: Yup.string().required(
        'The VAT ID is a required field for a business purchase',
      ),
      otherwise: Yup.string().notRequired(),
    }),
    companyName: Yup.string().when('isBusinessPurchase', {
      is: true,
      then: Yup.string()
        .max(
          MAX_CHARACTERS_FULL,
          `Company name must be at most ${MAX_CHARACTERS_FULL} characters`,
        )
        .required('Company name is a required field'),
      otherwise: Yup.string()
        .max(
          MAX_CHARACTERS_FULL,
          `Company name must be at most ${MAX_CHARACTERS_FULL} characters`,
        )
        .notRequired(),
    }),
    name: Yup.string().when('isBusinessPurchase', {
      is: true,
      then: Yup.string()
        .max(
          MAX_CHARACTERS_CUT,
          `Name must be at most ${MAX_CHARACTERS_CUT} characters`,
        )
        .required('Name is a required field'),
      otherwise: Yup.string()
        .max(
          MAX_CHARACTERS_FULL,
          `Name must be at most ${MAX_CHARACTERS_FULL} characters`,
        )
        .required('Name is a required field'),
    }),
    street: Yup.string().required('Street is a required field'),
    city: Yup.string().min(2).required('City is a required field'),
    state: Yup.string(),
    code: Yup.string(),
  };

  const teamSchema = {
    domain: Yup.string()
    .matches(DOMAIN_REGEX, 'Must be a valid domain')
    .required('Domain is a required field')
    .test(
      'legacy-domain',
      'This domain is using per-user billing. Please contact support to purchase a plan.',
      (value) => handleDomainChange(value),
    ),
  };

  const isWorkspacePlan = plan === 'TEAM';

  return Yup.object().shape({
    ...baseSchema,
    ...(isWorkspacePlan ? teamSchema : {}),
  });
};

export const LIST_OF_EU_CONTRIES = [
  'HU',
  'CY',
  'FR',
  'BE',
  'IE',
  'RO',
  'LV',
  'PL',
  'DK',
  'FI',
  'GB',
  'LT',
  'HR',
  'IT',
  'SI',
  'GR',
  'SK',
  'ES',
  'AT',
  'CZ',
  'NL',
  'LU',
  'DE',
  'SE',
  'PT',
  'EE',
  'BG',
  'MT',
];
