import React, { memo } from 'react';
import useLogic from './logic';
import {
  Component,
  ComponentContainer,
  Container,
  Error,
  ErrorIcon,
  Label,
  Optional,
} from './styles';
import { Props } from './types';

const Input = ({ className, isOptional, label, name, validateOnMount = false, ...props }: Props) => {
  const { error, hasError, onBlur, onChange, value } = useLogic({ name, validateOnMount });

  return (
    <Container className={className}>
      <Label htmlFor={name}>
        {label} {isOptional ? <Optional>(optional)</Optional> : null}
      </Label>
      <ComponentContainer>
        <Component
          $hasError={hasError}
          $hasValue={!!value}
          id={name}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          {...props}
        />
        {hasError ? <ErrorIcon /> : null}
      </ComponentContainer>
      <Error>{hasError ? error : null}</Error>
    </Container>
  );
};

export default memo(Input);
