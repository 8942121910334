import { Formik } from 'formik';
import React from 'react';
import { useConnect } from './connect';
import { useLogic } from './logic';
import { validationSchema } from './constants';
import {
  Back,
  Column,
  Container,
  Content,
  Header,
  Icon,
  Title,
  BillingMessage,
  FormPlaceholder,
  OrderSummaryPlaceholder,
  SubtitleMessage,
} from './styles';
import { Props } from './types';
import { SubmitForm } from './SubmitForm';

const Pricing = ({ plan, seats, billingInterval, canMonthly }: Props) => {
  const {
    typeOfError,
    error,
    enableReinitialize,
    taxRate,
    taxRateIncluded,
    currency,
    isEuropeanCountry,
    initialValues,
    isLoading,
    handleSubmit,
    handleDomainChange,
    handleSeatsChange,
    handleBillingIntervalChange,
    handleCountryChange,
    handleTypeOfBusinessChange,
    setError,
    disableCheckoutButton,
    typeOfBusiness,
    freeUserQuota,
  } = useConnect({ plan, seats, billingInterval, canMonthly });
  const { amount, quota } = useLogic({
    plan,
    seats,
    billingInterval,
    currency,
    canMonthly,
    freeUserQuota,
  });
  return (
    <Container>
      {error !== null ? (
        <BillingMessage
          isOpen
          variant={error.type}
          onClose={() => setError(null)}
          title={error.title}
          message={error.message}
          id={typeOfError || ''}
        />
      ) : null}
      <Header>
        <Back to="/settings/billing">
          <Icon />
        </Back>
        <Title>Billing information</Title>
      </Header>

      <SubtitleMessage>
        This information will appear on your invoice. We won&apos;t be able to
        change your invoice after the payment.
      </SubtitleMessage>

      {isLoading ? (
        <Content>
          <Column $isLoading>
            <FormPlaceholder />
          </Column>
          <Column>
            <OrderSummaryPlaceholder />
          </Column>
        </Content>
      ) : (
        <Formik
          enableReinitialize={enableReinitialize}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema(plan, handleDomainChange)}
        >
          <SubmitForm
            plan={plan}
            initialValues={initialValues}
            onChange={handleCountryChange}
            europeanCountry={isEuropeanCountry}
            handleTypeOfBusinessChange={handleTypeOfBusinessChange}
            amount={amount}
            typeOfBusiness={typeOfBusiness}
            quota={quota}
            currency={currency}
            billingInterval={billingInterval}
            canMonthly={canMonthly}
            taxRate={taxRate}
            taxRateIncluded={taxRateIncluded}
            onSeatsChange={handleSeatsChange}
            onBillingIntervalChange={handleBillingIntervalChange}
            disableCheckoutButton={disableCheckoutButton}
          />
        </Formik>
      )}
    </Container>
  );
};

export default Pricing;
