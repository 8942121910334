import { clientV2 } from 'data/client';
import debounce from 'lodash.debounce';
import { UserRolloutInput } from 'model/user';


export async function isDomainRollout(domainName: string,
) {
  const { data } = await clientV2.get<UserRolloutInput>(
    `/users/workspace-activation-status/domain-eligibility@${domainName}`,
    {
      params: {
        checkIsRegistered: false,
        throw404: false,
        includeUserV1: false,
        includeUserV2: false,
        includeUserV2Workspace: false,
        includeFBData: false,
      },
    },
  );

  return data.isEligible;
}


const debouncedFn = debounce((domain: string, resolve: (value: boolean) => void) => {
  isDomainRollout(domain).then(resolve);
}, 500);

export const debounceIsDomainRollout = (domainName: string): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    debouncedFn(domainName, resolve);
  });
};