import {useField, useFormikContext} from 'formik';
import {useEffect} from "react";
import { LogicProps } from './types';

const useLogic = ({ name, validateOnMount }: LogicProps) => {
  const {validateField} = useFormikContext();  // Access validateField
  const [{onBlur, onChange, value}, {error, touched}] = useField<string>({name});
  const hasError = validateOnMount ? !!error : !!error && touched;
  useEffect(() => {
    if (validateOnMount) {
      validateField(name);
    }
  }, [validateOnMount]);

  return {
    error,
    hasError,
    onBlur,
    onChange,
    value,
  };
};

export default useLogic;
